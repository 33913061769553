const hobbies = [
  "Web Design",
  "UI/UX",
  "Technology & Innovation",
  "Music",
  "Nomad Lifestyle",
  "Traveling",
  "Nature Wandering",
  "Photography",
  "Mountain Biking",
  "Snowboarding",
  "Paddleboarding",
  "Basketball",
];

export { hobbies };
